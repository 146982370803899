import { useEffect, useRef, useState } from 'react';
import { useApiFetch } from './api/useApiFetch';

export const useGeolocationAddress = () => {
    const { fetchData } = useApiFetch();
    const [center, setCenter] = useState([55.75, 37.57]);
    const [loading, setLoading] = useState(false);
    const loaded = useRef(false);

    const getCenter = async () => {
        setLoading(true);

        const response = await fetchData('/regions/center', 'get', true);

        if (response.success && Array.isArray(response.data)) {
            setCenter(response.data);
        }

        loaded.current = true;
        setLoading(false);
    };

    useEffect(() => {
        getCenter();
    }, []);

    return {
        center,
        loaded: loaded.current,
        loading,
    };
};
