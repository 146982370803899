import { TListFilter, TListSorters } from '../../types/api/requests';
import {
    TTableSetFiltersAction, TTableSetPrimaryKeyAction, TTableSetSelectedRowsAction, TTableSetSortersAction, TTableUpdateDataSourceAction, TTablesStateDropAction,
} from '../../types/redux/tables';

export const TABLE_SET_SELECTED_ROWS = 'TABLE_SET_SELECTED_ROWS';
export const TABLE_SET_FILTERS = 'TABLE_SET_FILTERS';
export const TABLE_SET_SORTERS = 'TABLE_SET_SORTERS';
export const TABLE_SET_PRIMARY_KEY = 'TABLE_SET_PRIMARY_KEY';
export const TABLES_STATE_DROP = 'TABLES_STATE_DROP';
export const TABLE_UPDATE_DATASOURCE = 'TABLE_UPDATE_DATASOURCE';

export const dropTablesState = (): TTablesStateDropAction => ({
    type: TABLES_STATE_DROP,
});

export const setSelectedRows = (tableName: string, selectedRows: object[]): TTableSetSelectedRowsAction => ({
    type: TABLE_SET_SELECTED_ROWS,
    payload: {
        selectedRows,
        tableName,
    },
});

export const setFilters = (tableName: string, filters: TListFilter, filterName?: string): TTableSetFiltersAction => ({
    type: TABLE_SET_FILTERS,
    payload: {
        tableName,
        filters,
        filterName,
    },
});

export const setSorters = (tableName: string, sorters: TListSorters): TTableSetSortersAction => ({
    type: TABLE_SET_SORTERS,
    payload: {
        tableName,
        sorters,
    },
});

export const setPrimaryKey = (tableName: string, primaryKey: string): TTableSetPrimaryKeyAction => ({
    type: TABLE_SET_PRIMARY_KEY,
    payload: {
        primaryKey,
        tableName,
    },
});

export const updateDataSource = (tableName: string, update = true): TTableUpdateDataSourceAction => ({
    type: TABLE_UPDATE_DATASOURCE,
    payload: {
        tableName,
        updateDataSource: update,
    },
});
