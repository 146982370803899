import { TPageLayoutItem } from '../types/layouts/page';

export const getLayoutItemClassName = (layout: TPageLayoutItem, isVertical: boolean) => {
    if (isVertical) {
        return 'page-object-horizontal-margin';
    }
    if (typeof layout.float === 'string') {
        return layout.float === 'left' ? 'layout-item-float-left' : 'layout-item-float-right';
    }
    return undefined;
};
