import React from 'react';

export interface ILayoutContext {
    uuid: string;
}

export const LayoutContext = React.createContext<ILayoutContext>({ uuid: '' });

export const LayoutContextWrapper = ({ children, uuid }: React.PropsWithChildren<ILayoutContext>) => (
    <LayoutContext.Provider value={{ uuid }}>
        {children}
    </LayoutContext.Provider>
);
