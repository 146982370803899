import { PlusOutlined } from '@ant-design/icons';
import {
    Button, Form, Input, Modal, Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { DefaultOptionType } from 'antd/lib/select';
import { useForm } from 'antd/lib/form/Form';
import { useApiFetch } from '../../hooks/api/useApiFetch';

interface IChatMessageModalProps {
    onHide: () => void;
}

const ChatMessageModal = ({ onHide }:IChatMessageModalProps) => {
    const { fetchData } = useApiFetch();
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<DefaultOptionType[]>([]);
    const [visible, setVisible] = useState(false);

    const filterOption = (input: string, option?: DefaultOptionType): boolean => {
        if (option instanceof Object && (typeof option.label === 'string' || typeof option.label === 'number')) {
            return option.label.toString().toLowerCase().includes(input.toLowerCase());
        }
        return false;
    };

    const getList = async () => {
        const response = await fetchData('/chats/members/select', 'post', true);
        if (response.success && Array.isArray(response.data)) {
            setOptions(response.data);
        }
    };

    const hide = () => {
        setVisible(false);
        onHide();
    };

    const show = () => setVisible(true);

    const onSendClick = async () => {
        setLoading(true);
        const fields = await form.validateFields();
        const response = await fetchData(`/chats/user/${fields.user_id}/send-message`, 'post', true, fields);
        if (response.success) {
            hide();
        }
        setLoading(false);
    };

    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={show}
            />
            <Modal
                title="Чат с пользователем"
                onCancel={hide}
                visible={visible}
                maskClosable={false}
                okText="Отправить"
                onOk={onSendClick}
                okButtonProps={{ loading }}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        label="Пользователь"
                        name="user_id"
                        rules={[{ required: true }]}
                    >
                        <Select
                            key={options.length}
                            showSearch
                            options={options}
                            filterOption={filterOption}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Сообщение"
                        name="message"
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ChatMessageModal;
