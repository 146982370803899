import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload as AntUpload } from 'antd';
import { HttpRequestHeader, RcFile, UploadFile } from 'antd/lib/upload/interface';
import { useEffect, useState } from 'react';
import { useApiFetch } from '../../hooks/api/useApiFetch';
import { IUploadLayout } from '../../types/layouts/upload';
import { IFormItemProps } from '../../factories/form';

interface IFile {
    id: string;
    url: string;
    original_name: string;
}

function Upload({
    additionalBody, formKey, listType, uploadAction, listApi, deleteAction, hidden,
}: IUploadLayout['config'] & IFormItemProps) {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileToUpload, setFileToUpload] = useState<RcFile>();
    const [uploading, setUploading] = useState(false);

    const { getHeaders, fetchData } = useApiFetch();

    const headers = getHeaders(true) as HttpRequestHeader;

    const beforeUpload = (file: RcFile) => {
        setFileToUpload(file);
        return false;
    };

    const getFileList = async () => {
        if (!hidden && typeof listApi === 'string') {
            const result = await fetchData<string|IFile[]>(listApi, 'get', true);
            const value = result.data;

            if (result.success) {
                if (typeof value === 'string') {
                    setFileList([{
                        name: 'image',
                        uid: '1',
                        url: value,
                    }]);
                }

                if (Array.isArray(value)) {
                    setFileList(value.map((val) => ({
                        name: val.original_name,
                        uid: val.id,
                        url: val.url,
                    })));
                }
            } else {
                setFileList([]);
            }
        }
    };

    const uploadFile = async () => {
        if (fileToUpload && typeof uploadAction === 'string') {
            setUploading(true);

            const formData = new FormData();
            formData.append(formKey, fileToUpload);

            if (additionalBody && typeof additionalBody === 'object') {
                const keys = Object.keys(additionalBody);
                keys.forEach((key) => {
                    formData.append(key, additionalBody[key]);
                });
            }

            const result = await fetchData(uploadAction, 'post', true, formData);

            if (result.success) {
                getFileList();
            }

            setFileToUpload(undefined);
            setUploading(false);
        }
    };

    const onRemove = async (file: UploadFile) => {
        if (typeof deleteAction === 'string') {
            setUploading(true);
            await fetchData(deleteAction.replace('{id}', file.uid), 'get', true);
            getFileList();
            setUploading(false);
        }
    };

    useEffect(() => {
        getFileList();
    }, []);

    useEffect(() => {
        uploadFile();
    }, [fileToUpload]);

    return (
        <AntUpload
            fileList={fileList}
            headers={headers}
            listType={listType}
            name={formKey}
            beforeUpload={beforeUpload}
            onRemove={onRemove}
        >
            <Button icon={<UploadOutlined />} loading={uploading}>Загрузить</Button>
        </AntUpload>
    );
}

export default Upload;
