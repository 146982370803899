import { TLayoutDropAction, TLayoutInitAction, TLayoutSetShouldUpdate } from '../../types/redux/layouts';

export const LAYOUT_INIT = 'LAYOUT_INIT';
export const LAYOUT_DROP = 'LAYOUT_DROP';
export const LAYOUT_SET_SHOULD_UPDATE = 'LAYOUT_SET_SHOULD_UPDATE';

export const layoutInit = (uuid: string): TLayoutInitAction => ({
    type: LAYOUT_INIT,
    payload: { uuid },
});

export const layoutDrop = (uuid: string): TLayoutDropAction => ({
    type: LAYOUT_DROP,
    payload: { uuid },
});

export const setShouldUpdate = (uuid: string, shouldUpdate: boolean): TLayoutSetShouldUpdate => ({
    type: LAYOUT_SET_SHOULD_UPDATE,
    payload: {
        shouldUpdate,
        uuid,
    },
});
