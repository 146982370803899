import { produce } from 'immer';
import { ILayoutsState, TLayoutsStateActions } from '../../types/redux/layouts';
import { LAYOUT_DROP, LAYOUT_INIT, LAYOUT_SET_SHOULD_UPDATE } from '../actions/layouts';

const initialState: ILayoutsState = {};

export const layouts = (state = initialState, action: TLayoutsStateActions): ILayoutsState => {
    switch (action.type) {
        case LAYOUT_INIT: {
            return produce(state, (draft) => {
                draft[action.payload.uuid] = {
                    ...draft[action.payload.uuid],
                    shouldUpdate: false,
                };
            });
        }
        case LAYOUT_DROP: {
            return produce(state, (draft) => {
                delete draft[action.payload.uuid];
            });
        }
        case LAYOUT_SET_SHOULD_UPDATE: {
            return produce(state, (draft) => {
                draft[action.payload.uuid] = {
                    ...draft[action.payload.uuid],
                    shouldUpdate: action.payload.shouldUpdate,
                };
            });
        }
        default: {
            return state;
        }
    }
};
