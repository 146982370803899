import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { MenuContextWrapper } from '../context/menu';
import AuthPage from '../pages/auth/auth';
import RegisterPage from '../pages/auth/register';
import LayoutPage from '../pages/layout';
import PageContainer from '../containers/page';
import { UserContextWrapper } from '../context/user';

export default function AppRouter() {
    return (
        <MenuContextWrapper>
            <UserContextWrapper>
                <Router>
                    <Routes>

                        {/* Страницы содержащие макет */}
                        <Route path="/" element={<PageContainer />}>
                            <Route path="/:objectName" element={<LayoutPage />} />
                            <Route path="/:objectName/:pageType" element={<LayoutPage />} />
                            <Route path="/:objectName/:pageType/:objectId" element={<LayoutPage />} />
                        </Route>

                        {/* Авторизация */}
                        <Route path="/auth" element={<AuthPage />} />
                        <Route path="/register" element={<RegisterPage />} />

                    </Routes>
                </Router>
            </UserContextWrapper>
        </MenuContextWrapper>
    );
}
