import { useEffect, useState } from 'react';

export interface IUsePageObject {
    shouldUpdateContent: boolean;
    updateContent: () => void;
}

export const usePageObject = (): IUsePageObject => {
    const [shouldUpdateContent, setShouldUpdateContent] = useState(false);

    const updateContent = () => setShouldUpdateContent(true);

    useEffect(() => {
        if (shouldUpdateContent) {
            setShouldUpdateContent(false);
        }
    }, [shouldUpdateContent]);

    return {
        shouldUpdateContent,
        updateContent,
    };
};
