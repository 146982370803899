import {
    DatePicker, Input, InputNumber, Select,
} from 'antd';
import MaskedInput from '../masked-input';
import Upload from '../upload';
import { TSelectType } from '../../types/layouts/select';
import { TInputType } from '../../types/layouts/input';
import { TTextAreaType } from '../../types/layouts/textarea';
import { TMaskedInputType } from '../../types/layouts/masked-input';
import { TDatePickerType } from '../../types/layouts/date-picker';
import { TInputNumberType } from '../../types/layouts/input-number';
import { TUploadType } from '../../types/layouts/upload';
import { TGeolocationAddressType } from '../../types/layouts/geolocation-address';
import GeolocationAddress from '../geolocation-address';

export type TFormItems = TSelectType | TInputType | TTextAreaType | TMaskedInputType | TDatePickerType | TInputNumberType | TUploadType | TGeolocationAddressType;

const FormItems: Record<TFormItems, any> = {
    input: Input,
    select: Select,
    textarea: Input.TextArea,
    'masked-input': MaskedInput,
    date: DatePicker,
    'input-number': InputNumber,
    upload: Upload,
    'geolocation-address': GeolocationAddress,
};

export default FormItems;
