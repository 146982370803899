import { Button, notification } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { useContext, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useApiFetch } from '../../hooks/api/useApiFetch';
import { IUseModalEdit } from '../../hooks/table/useModalEdit';
import { IUsePageObject } from '../../hooks/usePageObject';
import { IButtonActionTarget, IButtonLayout } from '../../types/layouts/button';
import { tableStateSelector } from '../../redux/selectors/tables';
import { setFilters, setSorters } from '../../redux/actions/tables';
import { LayoutContext } from '../../context/layout';
import { setShouldUpdate } from '../../redux/actions/layouts';

interface IActionButtonProps {
    params: IButtonLayout;
    modalEdit?: IUseModalEdit;
    hook?: IUsePageObject;
}

const ActionButton = ({ params, modalEdit, hook }: IActionButtonProps) => {
    const [loading, setLoading] = useState(false);
    const api = useApiFetch();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { objectName } = useParams();
    const tableState = useSelector(tableStateSelector(params.actionTarget?.name));
    const tableStateForVisibility = useSelector(tableStateSelector(params.visibleWhen?.target.name));
    const layoutContext = useContext(LayoutContext);

    const executed = useRef(false);

    const fetchAction = async (body?: any) => {
        if (typeof params.action === 'string') {
            setLoading(true);

            const result = await api.fetchData<IButtonActionTarget>(params.action, params.actionMethod || 'get', true, body);

            if (result.success) {
                notification.success({ message: result.message });
                executed.current = true;
                if (result.data && result.data.actionTarget?.type === 'list') {
                    if (Array.isArray(result.data.actionTarget.filters)) {
                        dispatch(setFilters(result.data.actionTarget.name, result.data.actionTarget.filters));
                    }
                }
            } else {
                notification.error({ message: result.message });
            }

            setLoading(false);
        }
    };

    const processAction = async () => {
        switch (params.actionTarget?.type) {
            case 'list': {
                await processListAction();
                break;
            }
            case 'modal': {
                processModalAction();
                break;
            }
            default: {
                await fetchAction();
            }
        }
    };

    const processListAction = async () => {
        switch (params.actionTarget?.operation) {
            case 'filter': {
                if (Array.isArray(params.actionTarget.filters)) {
                    dispatch(setFilters(params.actionTarget.name, params.actionTarget.filters, params.actionTarget.filterName));
                }
                if (Array.isArray(params.actionTarget.sorters)) {
                    dispatch(setSorters(params.actionTarget.name, params.actionTarget.sorters));
                }
                break;
            }
            case 'selectedRows': {
                if (typeof params.actionTarget?.name === 'string') {
                    await fetchAction(tableState?.selectedRows || []);
                }
                break;
            }
        }
    };

    const processModalAction = () => {
        modalEdit?.show();
        modalEdit?.getLayout({
            objectName,
            pageType: params.action,
        });
    };

    const onClick = async () => {
        if (typeof params.action === 'string') {
            if (typeof params.actionMethod === 'string') {
                await processAction();
                if (params.shouldUpdateContent) {
                    hook?.updateContent();
                }
                if (params.shouldUpdateLayout) {
                    dispatch(setShouldUpdate(layoutContext.uuid, true));
                }
            } else {
                navigate(`/${objectName}/${params.action}/`);
            }
        }
        if (typeof params.href === 'string') {
            navigate(params.href);
        }
    };

    const isDisabled = () => {
        if (typeof params.href === 'undefined' && params.actionTarget?.type === 'list') {
            if (params.actionTarget.operation === 'filter') {
                return JSON.stringify(tableState?.filters || []) === JSON.stringify(params.actionTarget.filters);
            }
        }
        return false;
    };

    const isHidden = () => {
        if (executed.current && params.hideAfterExecute) {
            return true;
        }
        if (params.visibleWhen) {
            switch (params.visibleWhen.target.type) {
                case 'list': {
                    return tableStateForVisibility?.filterName !== params.visibleWhen.target.filterName;
                }
            }
        }
        return false;
    };

    let Icon;

    if (typeof params.icon === 'string') {
        // @ts-ignore
        Icon = Icons[params.icon];
    }

    const disabled = isDisabled();

    return (
        <Button
            hidden={isHidden()}
            disabled={disabled}
            loading={loading}
            onClick={onClick}
            type={params.buttonType as ButtonType}
            danger={params.buttonType === 'danger'}
            icon={Icon ? <Icon /> : undefined}
        >
            {disabled && typeof Icon !== 'undefined' ? '' : params.title}
        </Button>
    );
};

ActionButton.defaultProps = {
    modalEdit: undefined,
    hook: undefined,
};

export default ActionButton;
