import { notification } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { apiConnections } from '../../../services/api/connections';
import { IAuthenticateRequest } from '../../../types/api/requests';
import { IAuthenticateResponse } from '../../../types/api/responses';
import { useApiFetch } from '../useApiFetch';
import { dropTablesState } from '../../../redux/actions/tables';

export const useAuthenticate = () => {
    const [loading, setLoading] = useState(false);

    const { fetchData, removeUserToken, saveUserToken } = useApiFetch();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const authenticate = async (body: IAuthenticateRequest) => {
        setLoading(true);

        const {
            method, url, useToken,
        } = apiConnections.authenticate;

        const result = await fetchData<IAuthenticateResponse>(url, method, useToken, body);

        if (result.data?.token) {
            notification.success({ message: result.message });
            saveUserToken(result.data.token);
            navigate('/');
        }

        setLoading(false);
    };

    const logout = () => {
        removeUserToken();
        navigate('/auth');
        dispatch(dropTablesState());
    };

    return {
        authenticate,
        loading,
        logout,
    };
};
