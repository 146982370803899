import { config } from '../../constants/config';
import { parseWebSocketMessage } from '../../helpers/pusher';

interface IPusherMessage {
    type: string;
    data?: any;
}

class PusherService {
    private messageListeners: Record<string, Function> = {};

    private openListeners: Function[] = [];

    private ws: WebSocket | undefined;

    close = () => {
        this.ws?.close();
        this.messageListeners = {};
        this.openListeners = [];
    };

    open = (channel: string) => {
        this.ws = new WebSocket(config.socketUrl, channel);
        this.ws.addEventListener('message', this.onMessage);
        this.ws.addEventListener('open', this.onOpen);
    };

    registerMessageListener = (type: string, func: Function) => {
        this.messageListeners[type] = func;
    };

    registerOpenListener = (func: Function) => {
        this.openListeners.push(func);
    };

    private onMessage = (message: MessageEvent) => {
        if (message.data) {
            const t = parseWebSocketMessage<IPusherMessage>(message.data);
            if (t && typeof this.messageListeners[t.type] === 'function') {
                this.messageListeners[t.type](t.data);
            }
        }
    };

    private onOpen = () => {
        this.openListeners.forEach((func) => {
            func();
        });
    };
}

export default PusherService;
