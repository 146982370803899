import { Spin } from 'antd';
import { useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLayout } from '../../hooks/useLayout';
import LayoutFactory from '../../factories/layout';
import { LayoutContextWrapper } from '../../context/layout';
import { layoutStateSelector } from '../../redux/selectors/layouts';

const LayoutPage = () => {
    const { objectName, pageType } = useParams();
    const {
        clearLayout, getLayout, layout, layoutLoading, uuid,
    } = useLayout();
    const layoutState = useSelector(layoutStateSelector(uuid));

    useEffect(() => {
        getLayout({ objectName, pageType });
        return () => {
            clearLayout();
        };
    }, [objectName, pageType]);

    useEffect(() => {
        if (layoutState?.shouldUpdate) {
            getLayout({ objectName, pageType });
        }
    }, [layoutState?.shouldUpdate]);

    return (
        <LayoutContextWrapper uuid={uuid}>
            <div className="layout-spin-container" hidden={!layoutLoading}>
                <Spin size="large" spinning tip="Загрузка макета..." />
            </div>
            <Scrollbars autoHide className="scrollable-content" hidden={layoutLoading}>
                {typeof objectName === 'string' && <LayoutFactory layout={layout} />}
            </Scrollbars>
        </LayoutContextWrapper>
    );
};

export default LayoutPage;
