import { Avatar, Conversation, ConversationList } from '@chatscope/chat-ui-kit-react';
import { Card, Input, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IChatListLayout } from '../../types/layouts/chat';
import { IChatListItem, useChatList } from '../../hooks/useChatList';
import ChatMessageModal from './message-modal';

function ChatList({ action, dataLink, title }: IChatListLayout) {
    const {
        list, loading, onNextPage, onSearch, loadingMore, refresh, search,
    } = useChatList(dataLink);

    const extra = (
        <div className="d-flex flex-1 justify-content-end">
            <Space>
                <Input.Search
                    allowClear
                    placeholder="Поиск..."
                    value={search || undefined}
                    onSearch={onSearch}
                />
                <ChatMessageModal onHide={() => refresh(true)} />
            </Space>
        </div>
    );

    const navigate = useNavigate();

    const onConversationClick = ({ id }: IChatListItem) => {
        navigate(action.replace('{id}', id));
    };

    return (
        <Card extra={extra} title={title}>
            <ConversationList
                loading={loading}
                style={{ height: 'calc(100vh - 205px)' }}
                onYReachEnd={onNextPage}
                loadingMore={loadingMore}
            >
                {list.map((item) => (
                    <Conversation
                        key={item.id}
                        name={item.name}
                        lastSenderName={item.lastMessage?.user}
                        info={<b>{item.lastMessage?.message}</b>}
                        onClick={() => onConversationClick(item)}
                        lastActivityTime={item.lastMessage?.created_at}
                        unreadDot={item.unread}
                    >
                        <Avatar src={item.lastMessage?.avatar} />
                    </Conversation>
                ))}
            </ConversationList>
        </Card>
    );
}

export default ChatList;
