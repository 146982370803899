import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Button, Dropdown, Menu, Modal, Result,
} from 'antd';
import { useSelector } from 'react-redux';
import FileDownload from 'js-file-download';
import { useState } from 'react';
import { IExportButtonLayout } from '../../types/layouts/export-button';
import { tableStateSelector } from '../../redux/selectors/tables';
import { useApiFetch } from '../../hooks/api/useApiFetch';

const ExportButton = ({
    excel, fileName, google, target,
}: IExportButtonLayout['config']) => {
    const api = useApiFetch();
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState<string>();
    const tableState = useSelector(tableStateSelector(target.name));

    const onExcelClick = async () => {
        setLoading(true);

        const body = tableState;

        if (body?.selectedRows && Array.isArray(body.selectedRows) && body.selectedRows.length > 0) {
            body.selectedRows = body.selectedRows.map((item) => item[tableState?.primaryKey || 'id']);
        }

        const result = await api.downloadFile(excel, 'post', true, body);

        if (result.success && result.data) {
            FileDownload(result.data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }

        setLoading(false);
    };

    const onGoogleClick = async () => {
        setLoading(true);

        const result = await api.fetchData<{url: string;}>(google, 'post', true, tableState);

        if (result.success && result.data) {
            setUrl(result.data.url);
        }

        setLoading(false);
    };

    const onMenuItemClick = (name: 'excel' | 'google') => {
        switch (name) {
            case 'excel': {
                onExcelClick();
                break;
            }
            case 'google': {
                onGoogleClick();
                break;
            }
        }
    };

    const onModalCancel = () => setUrl(undefined);

    const overlay = (
        <Menu>
            <Menu.Item onClick={() => onMenuItemClick('excel')}>
                Microsoft Excel (XLSX)
            </Menu.Item>
            <Menu.Item onClick={() => onMenuItemClick('google')}>
                Google Таблицы
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={overlay}>
                <Button title="Экспорт" loading={loading} icon={<UploadOutlined />}>
                    <DownOutlined />
                </Button>
            </Dropdown>
            <Modal
                title="Google Таблицы"
                visible={typeof url === 'string'}
                footer={null}
                onCancel={onModalCancel}
            >
                <Result
                    status="success"
                    title="Таблица успшено экспортирована в Google Таблицы"
                    extra={(
                        <Button
                            type="primary"
                            href={url}
                            target="_blank"
                        >
                            Перейти
                        </Button>
                    )}
                />

            </Modal>
        </>
    );
};

export default ExportButton;
