import { Button, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { produce } from 'immer';
import { IListFilter } from '../../types/api/requests';

interface INumericFilterProps extends FilterDropdownProps {
    onApply: (selectedKeys: string[], operator: IListFilter['operator']) => void;
    filteredValue?: IListFilter | null;
}

const NumericFilter: React.FC<INumericFilterProps> = ({
    confirm, clearFilters, filteredValue, onApply, selectedKeys, setSelectedKeys,
}) => {
    const [operator, setOperator] = useState<IListFilter['operator']>();

    useEffect(() => {
        setOperator(filteredValue?.operator);
    }, [filteredValue]);

    const onApplyClick = () => {
        if (typeof onApply === 'function') {
            const filterValue = operator === 'between' ? selectedKeys : [selectedKeys[0]];
            onApply(filterValue as string[], operator);
        }
        confirm();
    };

    const onClearClick = () => {
        if (typeof clearFilters === 'function') {
            clearFilters();
        }
        if (typeof onApply === 'function') {
            onApply([], operator);
        }
        confirm();
    };

    const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>, secondValue = false) => {
        setSelectedKeys(produce(selectedKeys, (draft) => {
            draft[secondValue ? 1 : 0] = target.value;
        }));
    };

    const onOperatorSelect = (type: IListFilter['operator']) => setOperator(type);

    return (
        <div className="ant-table-filter-dropdown">
            <Space className="ant-table-filter-dropdown-search w-100" direction="vertical">
                <Input type="number" value={selectedKeys[0]} onChange={onChange} />
                {operator === 'between' && (
                    <Input type="number" value={selectedKeys[1]} onChange={(e) => onChange(e, true)} />
                )}
            </Space>
            <Space direction="vertical" className="ant-table-filter-dropdown-btns">
                <Space direction="horizontal">
                    <Button
                        size="small"
                        onClick={() => onOperatorSelect('=')}
                        type={operator === '=' ? 'primary' : 'default'}
                    >
                        Равно
                    </Button>
                    <Button
                        size="small"
                        onClick={() => onOperatorSelect('>')}
                        type={operator === '>' ? 'primary' : 'default'}
                    >
                        Больше
                    </Button>
                    <Button
                        size="small"
                        onClick={() => onOperatorSelect('<')}
                        type={operator === '<' ? 'primary' : 'default'}
                    >
                        Меньше
                    </Button>
                    <Button
                        size="small"
                        onClick={() => onOperatorSelect('between')}
                        type={operator === 'between' ? 'primary' : 'default'}
                    >
                        Между
                    </Button>
                </Space>
            </Space>
            <Space className="ant-table-filter-dropdown-btns" direction="horizontal">
                <Button
                    type="link"
                    size="small"
                    onClick={onClearClick}
                >
                    Сбросить
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={onApplyClick}
                >
                    Применить
                </Button>
            </Space>
        </div>
    );
};

NumericFilter.defaultProps = {
    filteredValue: undefined,
};

export default NumericFilter;
