import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { IPageLayoutResponse } from '../types/api/responses';
import { useApiFetch } from './api/useApiFetch';
import { layoutDrop, layoutInit, setShouldUpdate } from '../redux/actions/layouts';

export interface IGetLayoutParams {
    objectName?: string;
    pageType?: string;
    layoutLink?: string;
}

export interface IUseLayout {
    clearLayout: () => void;
    getLayout: (params: IGetLayoutParams) => Promise<void>;
    layout?: IPageLayoutResponse;
    layoutLoading: boolean;
    uuid: string;
}

export const useLayout = (): IUseLayout => {
    const [layout, setLayout] = useState<IPageLayoutResponse>();
    const [layoutLoading, setLayoutLoading] = useState(false);

    const uuid = useMemo(() => uuidV4(), []);
    const api = useApiFetch();
    const dispatch = useDispatch();

    const clearLayout = () => setLayout(undefined);

    const getLayout = async ({ objectName, pageType, layoutLink }: IGetLayoutParams) => {
        dispatch(setShouldUpdate(uuid, false));

        setLayoutLoading(true);

        const routeLayoutLink = pageType ? `/${objectName}/${pageType}/layout` : `/${objectName}/layout`;

        const result = await api.fetchData<IPageLayoutResponse>(layoutLink ?? routeLayoutLink, 'get', true);

        if (result.success && result.data) {
            setLayout(result.data);
        }

        setLayoutLoading(false);
    };

    useEffect(() => {
        dispatch(layoutInit(uuid));
        return () => {
            dispatch(layoutDrop(uuid));
        };
    }, []);

    return {
        clearLayout,
        getLayout,
        layout,
        layoutLoading,
        uuid,
    };
};
