import { produce } from 'immer';
import { IChatMessage } from '../hooks/useChat';

export const SYSTEM_UNREAD_MESSAGE_ID = 'SYSTEM_UNREAD_MESSAGE_ID';

export const addSystemMessages = (messages: IChatMessage[]): IChatMessage[] => produce(messages, (draft) => {
    const unreadIndex = draft.findIndex((el) => !el.read);

    if (unreadIndex > -1) {
        draft.splice(unreadIndex, 0, {
            id: SYSTEM_UNREAD_MESSAGE_ID,
            avatar: '',
            direction: 'incoming',
            message: 'Непрочитанные сообщения',
            sender: '',
            sentTime: '',
            system: true,
            created_at: draft[unreadIndex].created_at,
        });
    }

    return draft;
});
