import { Button, Card } from 'antd';
import {
    MainContainer, ChatContainer, MessageList, Message, MessageInput, Avatar,
} from '@chatscope/chat-ui-kit-react';
import { useEffect, useRef } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { IChatLayout } from '../../types/layouts/chat';
import { IChatMessage, useChat } from '../../hooks/useChat';
import { SYSTEM_UNREAD_MESSAGE_ID, addSystemMessages } from '../../helpers/chat';
import ChatMediaMessage from './media-message';

function Chat(props: IChatLayout) {
    const { isAdmin, title } = props;

    const {
        deletingMessage, list, loading, onDelete, onMessageSend, sendLoading, nextPage,
    } = useChat(props);

    const scrolledToUnreadMessages = useRef(false);

    const messages = addSystemMessages(list);

    useEffect(() => {
        if (messages.length && !scrolledToUnreadMessages.current) {
            scrolledToUnreadMessages.current = true;
            const scrollDiv = document.getElementById('messages-list')?.lastChild as HTMLElement;
            const unreadMessagesTitle = document.getElementById(SYSTEM_UNREAD_MESSAGE_ID);
            if (scrollDiv && unreadMessagesTitle) {
                scrollDiv.scrollTo(0, unreadMessagesTitle.offsetTop);
            }
        }
    }, [messages]);

    const getMessageStatus = (message: IChatMessage): string => {
        if (message.pending) {
            return '⏳';
        }
        if (message.received) {
            return '✓✓';
        }
        return '✓';
    };

    const renderMessage = (message: IChatMessage) => {
        if (message.system) {
            return (
                <Message
                    key={message.id}
                    id={SYSTEM_UNREAD_MESSAGE_ID}
                    className="chat-system-message"
                    model={{
                        position: 'single',
                        direction: 'incoming',
                        message: message.message,
                    }}
                />
            );
        }
        return (
            <Message
                key={message.id}
                model={{
                    message: message.message,
                    sender: message.sender,
                    direction: message.direction,
                    position: 0,
                }}
            >
                <Message.Header sender={message.sender} sentTime={message.sentTime} />
                {message.direction === 'incoming' && (
                    <Avatar src={message.avatar} />
                )}
                <Message.Footer>
                    {isAdmin && (
                        <Button
                            color="danger"
                            danger
                            type="link"
                            icon={<DeleteOutlined />}
                            size="small"
                            onClick={() => onDelete(message)}
                            loading={deletingMessage === message.id}
                        />
                    )}
                    {message.direction === 'outgoing' && (
                        <div className="d-flex flex-1 justify-content-end"><span>{getMessageStatus(message)}</span></div>
                    )}
                </Message.Footer>
                {Array.isArray(message.files) && message.files.length > 0 && (
                    <Message.CustomContent>
                        <ChatMediaMessage message={message} />
                    </Message.CustomContent>
                )}
            </Message>
        );
    };

    return (
        <Card title={title} style={{ maxHeight: 'calc(100vh - 88px)', height: '100%', overflow: 'hidden' }}>
            <MainContainer>
                <ChatContainer>
                    <MessageList id="messages-list" style={{ height: 'calc(100vh - 255px)' }} loading={loading} onYReachStart={nextPage}>
                        {messages.map(renderMessage)}
                    </MessageList>
                    <MessageInput placeholder="Введите сообщение" attachButton={false} onSend={onMessageSend} sendDisabled={loading || sendLoading} />
                </ChatContainer>
            </MainContainer>
        </Card>
    );
}

export default Chat;
