import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { selectedRowsSelector } from '../../redux/selectors/tables';
import { TTableSelectedRows } from '../../types/redux/tables';
import { setPrimaryKey, setSelectedRows } from '../../redux/actions/tables';
import { ITableLayout } from '../../types/layouts/table';
import { useDataSource } from './useDataSource';
import { useModalEdit } from './useModalEdit';

export const useTable = (tableLayout: ITableLayout) => {
    const { name, primaryKey } = tableLayout;

    const dispatch = useDispatch();
    const ds = useDataSource(tableLayout);
    const modal = useModalEdit();
    const selectedRows = useSelector(selectedRowsSelector(name));
    const [searchParams, setSearchParams] = useSearchParams();
    const { objectName } = useParams();

    const modalEdit = searchParams.get('modalEdit');

    const setSelectedRowsHandler = (rows: TTableSelectedRows) => dispatch(
        setSelectedRows(name, rows),
    );

    useEffect(() => {
        dispatch(setPrimaryKey(name, primaryKey));
    }, []);

    useEffect(() => {
        if (typeof modalEdit === 'string') {
            modal.getLayout({
                layoutLink: `/${objectName}/${tableLayout.actions?.edit.action}/layout?${tableLayout.primaryKey}=${modalEdit}`,
            });
            modal.show(modalEdit);
            searchParams.delete('modalEdit');
            setSearchParams(searchParams);
        }
    }, [modalEdit]);

    return {
        ds,
        modal,
        selectedRows,
        setSelectedRows: setSelectedRowsHandler,
        tableLayout,
    };
};
