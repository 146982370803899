/* eslint-disable react/no-this-in-sfc */
import { Placemark as YPlacemark, useYMaps } from '@pbe/react-yandex-maps';
import { useState } from 'react';
import { IUseModalEdit } from '../../hooks/table/useModalEdit';

interface IPlacemarkProps {
    order: any;
    modal: IUseModalEdit;
}

function OrderPlacemark({ order, modal }: IPlacemarkProps) {
    const ymaps = useYMaps(['templateLayoutFactory']);

    const [inFocus, setInFocus] = useState(false);

    const avatar = typeof order.meeting_time === 'string' ? `<span class="tracking-map-placemark-icon order-placemark-icon"><b>${order.meeting_time}</b></span>` : '<img class="tracking-map-placemark-icon order-placemark-icon" src="/assets/icons/task.svg" />';

    const getIconLayout = (html: string) => {
        const layout = ymaps?.templateLayoutFactory.createClass(html, {
            build() {
                // @ts-ignore
                layout?.superclass?.build.call(this);
                // @ts-ignore
                this.getData().options.set('shape', {
                    type: 'Circle',
                    coordinates: [25, 25],
                    radius: 25,
                });
            },
        });
        return layout;
    };

    const focusedLayout = getIconLayout(`
        <div class="tracking-map-placemark">
        <span class="tracking-map-placemark-left-span"><b>Комментарий логиста:</b>\n${order.logistician_commentary || '-'}</span>
            ${avatar}
            <span class="tracking-map-placemark-bottom-span">${order.product}</span>
        </div>
    `);

    const unfocusedLayout = getIconLayout(`
        <div class="tracking-map-placemark">
            ${avatar}
            <span class="tracking-map-placemark-bottom-span">${order.product}</span>
        </div>
    `);

    const onClick = () => {
        modal.getLayout({
            layoutLink: `/orders/update/layout?id=${order.id}`,
        });
        modal.show(order.id);
    };

    return (
        <YPlacemark
            geometry={[order.latitude, order.longitude]}
            options={{
                iconLayout: inFocus ? focusedLayout : unfocusedLayout,
            }}
            onMouseEnter={() => setInFocus(true)}
            onMouseLeave={() => setInFocus(false)}
            onClick={onClick}
        />
    );
}

export default OrderPlacemark;
