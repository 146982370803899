import { Modal, Spin } from 'antd';
import React from 'react';
import { IUseModalEdit } from '../../hooks/table/useModalEdit';
import LayoutFactory from '../../factories/layout';
import { LayoutContextWrapper } from '../../context/layout';

interface IModalEditProps {
    hook: IUseModalEdit;
    objectName?: string;
}

const ModalEdit: React.FC<IModalEditProps> = ({ hook, objectName }) => (
    <LayoutContextWrapper uuid={hook.uuid}>
        <Modal visible={hook.visible} onCancel={hook.hide} footer={null} title={hook.layout?.title} width={hook.layout?.width} maskClosable={false}>
            <Spin spinning={hook.layoutLoading}>
                <LayoutFactory layout={hook.layout} modalEdit={hook} objectName={objectName} />
                {hook.layoutLoading && (
                    <div style={{ minHeight: 150 }} />
                )}
            </Spin>
        </Modal>
    </LayoutContextWrapper>
);

ModalEdit.defaultProps = {
    objectName: undefined,
};

export default ModalEdit;
