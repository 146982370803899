import { useEffect, useRef, useState } from 'react';
import { useApiFetch } from './api/useApiFetch';
import { useModalEdit } from './table/useModalEdit';
import PusherService from '../services/pusher';

export const useTrackingMapOrders = () => {
    const { fetchData } = useApiFetch();
    const [orders, setOrders] = useState<any[]>([]);
    const modal = useModalEdit();

    const socket = useRef<PusherService>();

    const getOrders = async () => {
        const response = await fetchData('/tracking-map/orders', 'get', true);
        if (response.success && Array.isArray(response.data)) {
            setOrders(response.data);
        }
    };

    const openSocket = () => {
        const conn = new PusherService();
        conn.registerMessageListener('table-updated', getOrders);
        conn.open('orders');
        socket.current = conn;
    };

    useEffect(() => {
        getOrders();
        openSocket();
        return () => {
            socket.current?.close();
        };
    }, []);

    useEffect(() => {
        if (modal.shouldUpdateTableData) {
            getOrders();
        }
    }, [modal.shouldUpdateTableData]);

    return {
        orders,
        modal,
    };
};
