/* eslint-disable react/no-this-in-sfc */
import { Placemark as YPlacemark, useYMaps } from '@pbe/react-yandex-maps';
import { useState } from 'react';
import { ITrackingMessageData } from '../../hooks/useTrackingMapSocket';

interface IPlacemarkProps {
    data: ITrackingMessageData;
    geometry: number[];
}

function MasterPlacemark({ data, geometry }: IPlacemarkProps) {
    const ymaps = useYMaps(['templateLayoutFactory']);

    const [inFocus, setInFocus] = useState(false);

    const borderColor = data.logisticianStatus ? `style="border-color: ${data.logisticianStatus.color}"` : 'style="border-color: #000;"';
    const avatar = typeof data.specializes_in === 'string' ? `<span class="tracking-map-placemark-icon master-placemark-icon" ${borderColor}><b>${data.specializes_in}</b></span>` : `<img class="tracking-map-placemark-icon master-placemark-icon" ${borderColor} src="/assets/icons/worker.svg" />`;

    const getIconLayout = (html: string) => {
        const layout = ymaps?.templateLayoutFactory.createClass(html, {
            build() {
                // @ts-ignore
                layout?.superclass?.build.call(this);
                // @ts-ignore
                this.getData().options.set('shape', {
                    type: 'Circle',
                    coordinates: [25, 25],
                    radius: 25,
                });
            },
        });
        return layout;
    };

    const focusedLayout = getIconLayout(`
        <div class="tracking-map-placemark">
            <span class="tracking-map-placemark-left-span"><b>Комментарий:</b>\n${data.commentary}</span>
            ${avatar}
            <span class="tracking-map-placemark-bottom-span">${data.name}</span>
        </div>
    `);

    const unfocusedLayout = getIconLayout(`
        <div class="tracking-map-placemark">
            ${avatar}
            <span class="tracking-map-placemark-bottom-span">${data.name}</span>
        </div>
    `);

    return (
        <YPlacemark
            geometry={geometry}
            options={{
                iconLayout: inFocus ? focusedLayout : unfocusedLayout,
            }}
            onMouseEnter={() => setInFocus(true)}
            onMouseLeave={() => setInFocus(false)}
        />
    );
}

export default MasterPlacemark;
