import { Image } from 'antd';
import { IChatMessage } from '../../hooks/useChat';

interface IChatMediaMessageProps {
    message: IChatMessage;
}

const ChatMediaMessage = ({ message }:IChatMediaMessageProps) => {
    const audioFiles = message.files?.filter((file) => file.type.includes('audio'));
    const images = message.files?.filter((file) => file.type.includes('image'));
    const videos = message.files?.filter((file) => file.type.includes('video'));

    return (
        <>
            {audioFiles && audioFiles.length > 0 && audioFiles.map((file) => (
                <audio controls src={file.uri} style={{ borderRadius: 10, maxHeight: 500, maxWidth: 500 }}>
                    <track default kind="captions" srcLang="ru" src={file.uri} />
                </audio>
            ))}
            {images && images.length > 0 && (
                <Image.PreviewGroup>
                    {images.map((image, index) => (
                        <Image
                            key={index}
                            src={typeof image.thumbnail === 'string' ? image.thumbnail : image.uri}
                            style={{ borderRadius: 10, marginLeft: index > 0 ? 10 : 0, width: 150 }}
                            preview={{ src: image.uri }}
                        />
                    ))}
                </Image.PreviewGroup>
            )}
            {videos && videos.length > 0 && videos.map((video) => (
                <video
                    controls
                    style={{ borderRadius: 10, maxHeight: 500, maxWidth: 500 }}
                    poster={video.thumbnail || undefined}
                    preload="none"
                    src={video.uri}
                >
                    <track default kind="captions" srcLang="ru" src={video.uri} />
                </video>
            ))}
        </>
    );
};

export default ChatMediaMessage;
