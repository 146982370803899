/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { SearchOutlined } from '@ant-design/icons';
import {
    Button, Checkbox, Input, Menu, Space,
    Spin,
} from 'antd';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/lib/table/interface';
import { produce } from 'immer';
import React, { useEffect, useState } from 'react';
import { IListFilter } from '../../types/api/requests';
import { useApiFetch } from '../../hooks/api/useApiFetch';

interface IFilterDropdownProps extends FilterDropdownProps {
    onApply: (filters: any[]) => void;
    filteredValue?: IListFilter | null;
    search?: boolean;
    filtersLink?: string;
}

const FilterDropdown: React.FC<IFilterDropdownProps> = ({
    confirm, filters: filtersFromProps, filtersLink, filteredValue, onApply, search: isForSearch, visible,
}) => {
    const api = useApiFetch();
    const [filters, setFilters] = useState(filtersFromProps || []);
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const getFilters = async () => {
        if (visible && typeof filtersLink === 'string') {
            setLoading(true);
            const response = await api.fetchData(filtersLink, 'get', true);
            if (response.success && Array.isArray(response.data)) {
                setFilters(response.data);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isForSearch) {
            setSearch(typeof filteredValue?.values[0] === 'string' ? filteredValue?.values[0] : '');
        } else {
            setSelected(filteredValue?.values || []);
        }
    }, [filteredValue?.values, isForSearch]);

    useEffect(() => {
        getFilters();
    }, [visible]);

    const onApplyClick = () => {
        if (typeof onApply === 'function') {
            if (isForSearch) {
                onApply(search.length > 0 ? [search] : []);
            } else {
                onApply(selected);
            }
        }
        confirm();
    };

    const onClearClick = () => {
        setSearch('');
        setSelected([]);
    };

    const onLiClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, value: any) => {
        e.preventDefault();
        onSelect(value);
    };

    const onSearch: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => setSearch(target.value);

    const onSelect = (value: any) => setSelected(produce(selected, (draft) => {
        const checked = draft.includes(value);
        if (!checked) {
            draft.push(value);
        } else {
            const index = draft.findIndex((el) => el === value);
            draft.splice(index, 1);
        }
    }));

    const renderItem = (f: ColumnFilterItem, key: number) => {
        const classList = ['ant-dropdown-menu-item'];
        const checked = selected.includes(f.value);

        if (checked) {
            classList.push('ant-dropdown-menu-item-selected');
        }

        return (
            <li key={key} className={classList.join(' ')} onClick={(e) => onLiClick(e, f.value)}>
                <Checkbox checked={checked} onChange={() => onSelect(f.value)}>
                    {f.text}
                </Checkbox>
            </li>
        );
    };

    const renderItems = () => {
        if (filters && filters.length > 0) {
            return filters.filter((el) => {
                if (typeof el.text === 'number' || typeof el.text === 'string') {
                    return el.text.toString().toLowerCase().includes(search.toLowerCase());
                }
                return !(typeof search === 'string' && search.length > 0);
            }).map(renderItem);
        }
        return null;
    };

    return (
        <div className="ant-table-filter-dropdown">
            <div className="ant-table-filter-dropdown-search">
                <Input
                    value={search}
                    prefix={<SearchOutlined />}
                    placeholder="Поиск..."
                    onChange={onSearch}
                />
            </div>
            {!isForSearch && (
                <Menu className="ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light ant-dropdown-menu-without-submenu">
                    {loading && (
                        <Spin spinning={loading}>
                            <div style={{ minHeight: 50 }} />
                        </Spin>
                    )}
                    {!loading && renderItems()}
                </Menu>
            )}
            <Space className="ant-table-filter-dropdown-btns" direction="horizontal">
                <Button
                    disabled={selected.length === 0}
                    type="link"
                    size="small"
                    onClick={onClearClick}
                >
                    Сбросить
                </Button>
                <Button
                    type="primary"
                    size="small"
                    onClick={onApplyClick}
                >
                    {isForSearch ? 'Поиск' : 'Применить'}
                </Button>
            </Space>
        </div>
    );
};

FilterDropdown.defaultProps = {
    filteredValue: undefined,
    search: undefined,
    filtersLink: undefined,
};

export default FilterDropdown;
