import axios, { AxiosResponse } from 'axios';

interface YandexGeocoderGetAddressResponse {
    response: {
        GeoObjectCollection: {
            featureMember: {
                GeoObject: {
                    metaDataProperty: {
                        GeocoderMetaData: {
                            text: string;
                        }
                    }
                }
            }[];
        }
    }
}

class YandexGeocoder {
    public static getAddress = async (geolocation: [number, number]) => {
        const result = await axios.get<any, AxiosResponse<YandexGeocoderGetAddressResponse, any>>(`https://geocode-maps.yandex.ru/1.x/?apikey=c335f94b-90e2-4332-8c0e-4e02515cf790&geocode=${geolocation.join(',')}&format=json`);
        return result.data;
    };
}

export default YandexGeocoder;
