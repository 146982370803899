import {
    Button, Checkbox, Form, Input, Layout,
} from 'antd';
import { UserOutlined, LockOutlined, LoginOutlined } from '@ant-design/icons';
import { useAuthenticate } from '../../hooks/api/auth/useAuthenticate';

const { Content } = Layout;

export default function AuthPage() {
    const auth = useAuthenticate();

    return (
        <Layout>
            <Content className="d-flex flex-1 flex-content-centered">
                <Form className="auth-form" layout="vertical" onFinish={auth.authenticate}>
                    <Form.Item
                        label="Эл. почта"
                        name="email"
                        required
                        rules={[{
                            required: true,
                            message: 'Введите эл. почту',
                        }, {
                            type: 'email',
                            message: 'Эл. почта введена неверно',
                        }]}
                    >
                        <Input prefix={<UserOutlined />} size="large" />
                    </Form.Item>
                    <Form.Item
                        label="Пароль"
                        name="password"
                        required
                        rules={[{ required: true, message: 'Введите пароль' }]}
                    >
                        <Input.Password prefix={<LockOutlined />} size="large" />
                    </Form.Item>
                    <Form.Item className="text-center" name="remember" valuePropName="checked">
                        <Checkbox>Запомнить меня</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            className="w-100"
                            type="primary"
                            htmlType="submit"
                            size="large"
                            icon={<LoginOutlined />}
                            loading={auth.loading}
                        >
                            Войти
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
}
