import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import { Provider } from 'react-redux';
import moment from 'moment';
import AppRouter from './router';
import './App.css';
import store from './redux/store';

import 'moment/locale/ru';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

moment.locale('ru');

function App() {
    return (
        <Provider store={store}>
            <ConfigProvider locale={ruRU}>
                <AppRouter />
            </ConfigProvider>
        </Provider>
    );
}

export default App;
