import { produce } from 'immer';
import { ITablesState, TTableActions } from '../../types/redux/tables';
import {
    TABLES_STATE_DROP, TABLE_SET_FILTERS, TABLE_SET_PRIMARY_KEY, TABLE_SET_SELECTED_ROWS, TABLE_SET_SORTERS, TABLE_UPDATE_DATASOURCE,
} from '../actions/tables';

const initialState: ITablesState = {};

export const tables = (state = initialState, action: TTableActions): ITablesState => {
    switch (action.type) {
        case TABLE_SET_SELECTED_ROWS: {
            return produce(state, (draft: ITablesState) => {
                draft[action.payload.tableName] = {
                    ...draft?.[action.payload.tableName],
                    selectedRows: action.payload.selectedRows,
                };
            });
        }
        case TABLE_SET_FILTERS: {
            return produce(state, (draft: ITablesState) => {
                draft[action.payload.tableName] = {
                    ...draft?.[action.payload.tableName],
                    filters: action.payload.filters,
                    filterName: action.payload.filterName,
                };
            });
        }
        case TABLE_SET_SORTERS: {
            return produce(state, (draft: ITablesState) => {
                draft[action.payload.tableName] = {
                    ...draft?.[action.payload.tableName],
                    sorters: action.payload.sorters,
                };
            });
        }
        case TABLE_SET_PRIMARY_KEY: {
            return produce(state, (draft: ITablesState) => {
                draft[action.payload.tableName] = {
                    ...draft?.[action.payload.tableName],
                    primaryKey: action.payload.primaryKey,
                };
            });
        }
        case TABLE_UPDATE_DATASOURCE: {
            return produce(state, (draft: ITablesState) => {
                draft[action.payload.tableName] = {
                    ...draft?.[action.payload.tableName],
                    updateDataSource: action.payload.updateDataSource,
                };
            });
        }
        case TABLES_STATE_DROP: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};
