import { Layout, Spin } from 'antd';
import { Outlet } from 'react-router-dom';
import AppMenu from '../components/menu';
import { useMenu } from '../hooks/api/useMenu';
import { useUser } from '../hooks/useUser';

const { Content, Header } = Layout;

const PageContainer = () => {
    const menu = useMenu();
    const user = useUser();

    return (
        <Layout>
            <Spin spinning={user.loading || menu.loading}>
                <Header>
                    <AppMenu />
                </Header>
            </Spin>
            <Content>
                <Outlet />
            </Content>
        </Layout>
    );
};

export default PageContainer;
