import { useContext } from 'react';
import { MenuContext } from '../../context/menu';
import { apiConnections } from '../../services/api/connections';
import { TMenuDataResponse } from '../../types/api/responses';
import { useApiFetch } from './useApiFetch';

export const useMenu = () => {
    const api = useApiFetch();

    const menuContext = useContext(MenuContext);

    const { menuData: conn } = apiConnections;

    /**
     * Загрузить список меню
     */
    const getMenuData = async () => {
        menuContext.loading.setter(true);

        const result = await api.fetchData<TMenuDataResponse>(conn.url, conn.method, conn.useToken);

        if (result.success && result.data && Array.isArray(result.data.elements)) {
            menuContext.data.setter(result.data.elements);
        }

        menuContext.loading.setter(false);
    };

    return {
        getMenuData,
        loading: menuContext.loading.value,
        menuData: menuContext.data.value,
    };
};
