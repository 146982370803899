import React, { useState } from 'react';
import { IUserInfo } from '../types/api/user';

export interface IUserContext {
    info: {
        value?: IUserInfo;
        setter: React.Dispatch<React.SetStateAction<IUserInfo|undefined>>;
    };
    loading: {
        value: boolean;
        setter: React.Dispatch<React.SetStateAction<boolean>>;
    };
}

export const UserContext = React.createContext<IUserContext>({
    info: {
        setter: () => {},
        value: undefined,
    },
    loading: {
        setter: () => {},
        value: false,
    },
});

export const UserContextWrapper: React.FC<{}> = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState<IUserInfo>();

    const value: IUserContext = {
        info: {
            value: info,
            setter: setInfo,
        },
        loading: {
            value: loading,
            setter: setLoading,
        },
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};
