import { IApiConnectionItem, IApiConnectionItemWithBody } from '../../types/api/fetch';
import { IAuthenticateRequest, IListRequest } from '../../types/api/requests';

export const apiConnections = {

    authenticate: {
        method: 'post',
        url: '/authenticate',
        createBody: (v: IAuthenticateRequest) => v,
        useToken: false,
    } as IApiConnectionItemWithBody<IAuthenticateRequest>,

    regionsList: {
        method: 'post',
        url: '/regions/list',
        createBody: (v: IListRequest) => v,
        useToken: true,
    } as IApiConnectionItemWithBody<IListRequest>,

    menuData: {
        method: 'get',
        url: '/menu',
        useToken: true,
    } as IApiConnectionItem,
};
