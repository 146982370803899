import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FilterValue } from 'antd/lib/table/interface';
import { useDispatch, useSelector } from 'react-redux';
import { produce } from 'immer';
import {
    IListFilter, IListRequest, TListFilter, TListSorters,
} from '../../types/api/requests';
import { useApiFetch } from '../api/useApiFetch';
import { ITableCardExtra, ITableLayout } from '../../types/layouts/table';
import { IListResponse, IListResponseSummary, IListResponseTableParams } from '../../types/api/responses';
import { tableStateSelector } from '../../redux/selectors/tables';
import { setFilters as setFiltersAction, setSorters as setSortersAction, updateDataSource } from '../../redux/actions/tables';
import PusherService from '../../services/pusher';

type TTableFilter = Record<string, FilterValue | null>;

export const useDataSource = ({
    columns, dataLink, pageSize: defaultPageSize, name,
}: ITableLayout) => {
    const [cardExtra, setCardExtra] = useState<ITableCardExtra>();
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(1);
    const [readyToFetch, setReadyToFetch] = useState(false);
    const [tableParams, setTableParams] = useState<IListResponseTableParams>();
    const [summary, setSummary] = useState<IListResponseSummary>();
    const tableState = useSelector(tableStateSelector(name));
    const [searchParams, setSearchParams] = useSearchParams();

    const page = parseInt(searchParams.get('page') || '1', 10);
    const pageSize = searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize') as string, 10) : defaultPageSize;
    const search = searchParams.get('search') || undefined;

    const api = useApiFetch();
    const { objectName } = useParams();
    const dispatch = useDispatch();
    const pusher = new PusherService();

    const filters = tableState?.filters;
    const sorters = tableState?.sorters;

    const setFilters = (f: TListFilter) => {
        dispatch(setFiltersAction(name, f));
    };

    const setSorters = (s: TListSorters) => {
        dispatch(setSortersAction(name, s));
    };

    const fetchDataSource = async (silent = false) => {
        if (readyToFetch) {
            if (!silent) {
                setLoading(true);
            }

            const body: IListRequest = {
                filters,
                sorters,
                count: pageSize,
                search,
            };

            const { data } = await api.fetchData<IListResponse>(dataLink ?? `/${objectName}/list?page=${page}`, 'post', true, body);

            setCardExtra(data?.cardExtra);
            setDataSource(data?.list || []);
            setTotal(data?.total || 1);
            setTableParams(data?.tableParams);
            setSummary(data?.summary);

            if (data?.list.length === 0) {
                searchParams.set('page', '1');
                setSearchParams(searchParams);
            }

            if (!silent) {
                setLoading(false);
            }

            dispatch(updateDataSource(name, false));
        }
    };

    const getDefaultSorters = () => {
        if (typeof sorters === 'undefined') {
            const defaultSorters: TListSorters = [];

            columns.forEach((column) => {
                if (typeof column.dataIndex === 'string' && typeof column.defaultSortOrder === 'string') {
                    defaultSorters.push({
                        column: column.dataIndex,
                        desc: column.defaultSortOrder === 'descend',
                    });
                }
            });

            setSorters(defaultSorters);
        }

        setReadyToFetch(true);
    };

    const onFilterChange = (filter: IListFilter) => {
        const nextState = produce(filters || [], (draft = []) => {
            const index = draft?.findIndex((f) => f.column === filter.column);
            if (typeof index === 'number' && draft?.[index] instanceof Object) {
                if (filter.values.length > 0) {
                    draft[index] = filter;
                } else {
                    draft.splice(index, 1);
                }
            } else if (filter.values.length > 0) {
                draft.push(filter);
            }
        });
        setFilters(nextState);
    };

    const onFiltersChange = (f: TListFilter | TTableFilter) => {
        setFilters(Array.isArray(f) ? f : []);
    };

    const onPageChange = (p: number, pZ: number) => {
        searchParams.set('page', p.toString());
        searchParams.set('pageSize', pZ.toString());
        setSearchParams(searchParams);
    };

    const onDNDropSortChange = async (newDs: any[]) => {
        setDataSource(newDs);
        setLoading(true);
        await api.fetchData(`/${objectName}/dndSort`, 'post', true, newDs);
        fetchDataSource();
    };

    const onSearch = (searchValue: string) => {
        if (searchValue.length === 0) {
            searchParams.delete('search');
        } else {
            searchParams.set('search', searchValue);
        }
        searchParams.set('page', '1');
        setSearchParams(searchParams);
    };

    useEffect(() => {
        fetchDataSource();
        return () => {
            setDataSource([]);
        };
    }, [page, filters, sorters, readyToFetch, pageSize, search]);

    useEffect(() => {
        getDefaultSorters();
    }, [objectName]);

    useEffect(() => {
        if (readyToFetch) {
            pusher.open(name);
            pusher.registerMessageListener('table-updated', () => {
                dispatch(updateDataSource(name));
            });
        }
        return () => {
            pusher.close();
        };
    }, [readyToFetch]);

    useEffect(() => {
        if (tableState?.updateDataSource) {
            fetchDataSource(true);
        }
    }, [tableState?.updateDataSource]);

    return {
        cardExtra,
        currentPage: page,
        dataSource,
        loading,
        total,
        filters,
        sorters,
        updateDataSource: fetchDataSource,
        onFilterChange,
        onFiltersChange,
        onSortersChange: setSorters,
        setLoading,
        tableParams,
        onDNDropSortChange,
        summary,
        onPageChange,
        pageSize,
        onSearch,
        search,
    };
};
