import React, { useState } from 'react';
import { IMenuItem } from '../types';

export interface IMenuContext {
    data: {
        value: IMenuItem[];
        setter: React.Dispatch<React.SetStateAction<IMenuItem[]>>;
    };
    loading: {
        value: boolean;
        setter: React.Dispatch<React.SetStateAction<boolean>>;
    };
}

export const MenuContext = React.createContext<IMenuContext>({
    data: {
        value: [],
        setter: () => {},
    },
    loading: {
        value: false,
        setter: () => {},
    },
});

export const MenuContextWrapper: React.FC<{}> = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [menuData, setMenuData] = useState<IMenuItem[]>([]);

    const value: IMenuContext = {
        data: {
            value: menuData,
            setter: setMenuData,
        },
        loading: {
            value: loading,
            setter: setLoading,
        },
    };

    return (
        <MenuContext.Provider value={value}>
            {children}
        </MenuContext.Provider>
    );
};
