/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    DatePicker, Input, Select as ASelect, Button, notification,
} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import InputMask from 'react-input-mask';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useApiFetch } from '../../hooks/api/useApiFetch';
import Select from '../select';
import { IListFilter } from '../../types/api/requests';
import { TTableColumn } from '../../types/layouts/table';
import { setFilters, setSorters } from '../../redux/actions/tables';

const Cell = (props: any) => {
    const {
        action, editable, editor, onMouseEnter, onMouseLeave, style, record, editorAction, primaryKey, updateTable, setTableLoading, className, dataIndex, hoverColumn,
    } = props;
    let { children } = props;

    const { fetchData } = useApiFetch();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [readyToEdit, setReadyToEdit] = useState(false);
    const [value, setValue] = useState(editor ? record[editor.name] : undefined);

    // eslint-disable-next-line no-undef
    const mouseEnterTimeout = useRef<NodeJS.Timeout>();
    const valueEdited = useRef(false);

    useEffect(() => {
        if (record && editor) {
            setValue(record[editor.name]);
        }
    }, [readyToEdit]);

    const onChange = (v: any) => {
        valueEdited.current = true;
        setValue(v);
    };

    const isAvailable = () => {
        let available = true;

        if (editor && Array.isArray(editor.available?.row)) {
            editor.available?.row.forEach((row: IListFilter) => {
                if (available) {
                    if (!row.values.includes(record[row.column])) {
                        available = false;
                    }
                }
            });
        }

        return available;
    };

    const onActionClick = (x: TTableColumn['action']) => {
        if (x instanceof Object) {
            if (typeof x.list === 'string') {
                if (Array.isArray(x.filters)) {
                    const nextFilters = x.filters.map((filter) => {
                        if (typeof filter.valueColumn === 'string') {
                            filter.values = [record[filter.valueColumn]];
                        }
                        return filter;
                    });
                    dispatch(setFilters(x.list, nextFilters));
                }
                if (Array.isArray(x.sorters)) {
                    dispatch(setSorters(x.list, x.sorters));
                }
            }
            if (typeof x.link === 'string') {
                navigate(x.link);
            }
        }
    };

    if (readyToEdit && typeof hoverColumn === 'string') {
        children = record[hoverColumn];
    }

    if (action instanceof Object) {
        children = (
            <Button type="link" onClick={() => onActionClick(action)}>
                {record[dataIndex]}
            </Button>
        );
    }

    if (isAvailable() && editable && readyToEdit) {
        switch (editor.type) {
            case 'date': {
                children = (
                    <DatePicker
                        // @ts-ignore
                        defaultValue={value ? moment(value, 'DD.MM.YYYY') : moment()}
                        {...editor.config}
                        onChange={onChange}
                        style={{
                            ...style,
                            backgroundColor: '#FFFFFF',
                        }}
                    />
                );
                break;
            }
            case 'masked': {
                children = (
                    <InputMask
                        className="ant-input"
                        defaultValue={value}
                        {...editor.config}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        style={{
                            ...style,
                            backgroundColor: '#FFFFFF',
                        }}
                    />
                );
                break;
            }
            case 'input': {
                children = (
                    <Input.TextArea
                        defaultValue={value}
                        {...editor.config}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        style={{
                            ...style,
                            backgroundColor: '#FFFFFF',
                        }}
                    />
                );
                break;
            }
            case 'input-number': {
                children = (
                    <Input
                        defaultValue={value}
                        {...editor.config}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                        style={{
                            ...style,
                            backgroundColor: '#FFFFFF',
                        }}
                        type="number"
                    />
                );
                break;
            }
            case 'select': {
                children = typeof editor.config?.listApi === 'string' ? (
                    <Select
                        formDataLoaded={readyToEdit}
                        {...editor.config}
                        value={value}
                        onChange={onChange}
                        style={{
                            ...style,
                            backgroundColor: '#FFFFFF',
                            width: '100%',
                        }}
                    />
                ) : (
                    <ASelect
                        {...editor.config}
                        value={value}
                        onChange={onChange}
                        onSearch={editor.config.acceptSearch ? onChange : undefined}
                        style={{
                            ...style,
                            backgroundColor: '#FFFFFF',
                            width: '100%',
                        }}
                    />
                );
            }
        }
    }

    const onMouseLeaveFn = async (e: any) => {
        if (mouseEnterTimeout.current) {
            clearTimeout(mouseEnterTimeout.current);
        }
        onMouseLeave(e);
        setReadyToEdit(false);
        if (valueEdited.current && editor && record[editor.name] !== value) {
            valueEdited.current = false;
            setTableLoading(true);
            const response = await fetchData(editorAction.replace('{objectId}', record[primaryKey]), 'post', true, {
                [editor.name]: editor.type === 'date' && value ? moment(value).format('YYYY-MM-DD') : value,
            });
            if (!response.success) {
                notification.error({ message: response.message });
            }
            updateTable();
        }
    };

    return React.createElement('td', {
        ...props,
        className,
        onMouseEnter: (e: any) => {
            onMouseEnter(e);
            mouseEnterTimeout.current = setTimeout(() => {
                setReadyToEdit(true);
            }, 250);
        },
        onMouseLeave: onMouseLeaveFn,
    }, children);
};

export default Cell;
