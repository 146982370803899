/* eslint-disable no-undef */
import { useEffect, useRef, useState } from 'react';
import { produce } from 'immer';
import { useSearchParams } from 'react-router-dom';
import { useApiFetch } from './api/useApiFetch';
import { IListResponse } from '../types/api/responses';
import PusherService from '../services/pusher';

export interface IChatListItem {
    id: string;
    name: string;
    lastMessage: {
        avatar: string;
        user: string;
        message: string;
        created_at: string;
    } | null;
    unread: boolean;
}

export const useChatList = (dataLink: string) => {
    const api = useApiFetch();
    const [deletedMessage, setDeletedMessage] = useState<string>();
    const [list, setList] = useState<IChatListItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [newMessageChat, setNewMessageChat] = useState<string>();
    const [lastPage, setLastPage] = useState<number>();
    const [showNextPage, setShowNextPage] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const page = typeof searchParams.get('page') === 'string' ? parseInt(searchParams.get('page') as string, 10) : 1;
    const search = searchParams.get('search');

    const socket = useRef<PusherService>();
    const listLoaded = useRef(false);

    const getList = async (woLoader = false) => {
        if (!woLoader) {
            setLoading(true);
        }

        const p = listLoaded.current ? page : 1;
        const result = await api.fetchData<IListResponse<IChatListItem>>(`${dataLink}?page=${p}`, 'post', true, { count: listLoaded.current ? 20 : page * 20, search });

        if (result.success && result.data && Array.isArray(result.data.list)) {
            if (!listLoaded.current || page === 1) {
                listLoaded.current = true;
                setList(result.data.list);
            } else {
                setList(produce(list, (draft) => {
                    // @ts-ignore
                    draft.push(...result.data.list);
                }));
            }
            setLastPage(Math.ceil(result.data.total / 20));
            setShowNextPage(false);
        }

        if (!woLoader) {
            setLoading(false);
        }
    };

    const onNextPage = () => setShowNextPage(true);

    const onSearch = (value: string) => {
        if (value.length) {
            searchParams.set('search', value);
        } else {
            searchParams.delete('search');
        }
        setSearchParams(searchParams);
    };

    const openSocket = () => {
        const conn = new PusherService();
        conn.registerMessageListener('deleted-message', setDeletedMessage);
        conn.registerMessageListener('new-message', setNewMessageChat);
        conn.open('chats-list');
        socket.current = conn;
    };

    const refresh = (clearSearch = false) => {
        if (clearSearch && typeof search === 'string') {
            searchParams.delete('search');
            setSearchParams(searchParams);
        } else {
            listLoaded.current = false;
            getList();
        }
    };

    useEffect(() => {
        getList();
    }, [dataLink, page]);

    useEffect(() => {
        if (listLoaded.current) {
            listLoaded.current = false;
            getList();
        }
    }, [search]);

    useEffect(() => {
        if (listLoaded.current) {
            listLoaded.current = false;
            getList(true);
        }
    }, [deletedMessage, newMessageChat]);

    useEffect(() => {
        openSocket();
        return () => {
            socket.current?.close();
        };
    }, []);

    useEffect(() => {
        if (showNextPage && typeof lastPage === 'number' && lastPage > page) {
            searchParams.set('page', (page + 1).toString());
            setSearchParams(searchParams);
        }
    }, [showNextPage]);

    return {
        list,
        loading: !listLoaded.current && loading,
        onNextPage,
        onSearch,
        loadingMore: listLoaded.current && typeof lastPage === 'number' && lastPage > page && loading,
        refresh,
        search,
    };
};
