import { MessageDirection } from '@chatscope/chat-ui-kit-react/src/types/unions';
import { produce } from 'immer';
import {
    useEffect, useRef, useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { Modal, notification } from 'antd';
import { useApiFetch } from './api/useApiFetch';
import PusherService from '../services/pusher';
import { IListResponse } from '../types/api/responses';
import { IChatLayout } from '../types/layouts/chat';
import { IListFilter } from '../types/api/requests';

export interface IChatMessageFile {
    type: string;
    name: string;
    uri: string;
    thumbnail: string|null;
}

export interface IChatMessage {
    id: string;
    avatar: string;
    message: string;
    sender: string;
    direction: MessageDirection;
    sentTime: string;
    created_at: Date;
    read?: boolean;
    received?: boolean;
    pending?: boolean;
    system?: boolean;
    files?: IChatMessageFile[];
}

const MESSAGES_PER_PAGE = 100;

export const useChat = ({
    chatChannel, dataLink, deleteMessageAction, sendMessageAction, messageDataLink,
}: IChatLayout) => {
    const [deletingMessage, setDeletingMessage] = useState<string>();
    const [list, setList] = useState<IChatMessage[]>([]);
    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [deletedMessage, setDeletedMessage] = useState<string>();
    const [deletedMessages, setDeletedMessages] = useState<string[]>([]);
    const [newMessage, setNewMessage] = useState<string>();
    const [receivedMessage, setReceivedMessage] = useState<string>();
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState<number>();
    const [showNextPage, setShowNextPage] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();

    const api = useApiFetch();

    const messagesLoaded = useRef(false);
    const socket = useRef<PusherService>();

    const getList = async () => {
        setLoading(true);

        let filters: IListFilter[] | undefined;

        if (messagesLoaded.current) {
            filters = [{
                column: 'created_at',
                values: [list[0].created_at as any],
                operator: '<',
            }];
        }

        const result = await api.fetchData<IListResponse<IChatMessage>>(dataLink, 'post', true, {
            count: MESSAGES_PER_PAGE,
            filters,
        });

        if (result.success && result.data && Array.isArray(result.data.list)) {
            if (!messagesLoaded.current || page === 1) {
                messagesLoaded.current = true;
                setList(result.data.list.reverse());
            } else {
                setList(produce(list, (draft) => {
                    // @ts-ignore
                    draft.unshift(...result.data.list.reverse());
                }));
            }
            setLastPage(result.data.lastPage);
        }

        setShowNextPage(false);
        setLoading(false);
    };

    const openSocket = () => {
        const conn = new PusherService();
        conn.registerMessageListener('deleted-message', setDeletedMessage);
        conn.registerMessageListener('deleted-messages', setDeletedMessages);
        conn.registerMessageListener('new-message', setNewMessage);
        conn.registerMessageListener('read-message', setReceivedMessage);
        conn.open(chatChannel);
        socket.current = conn;
    };

    const onDeleteConfirmed = async (message: IChatMessage) => {
        setDeletingMessage(message.id);
        await api.fetchData(deleteMessageAction.replace('{id}', message.id), 'post', true, message);
        setDeletingMessage(undefined);
    };

    const onDelete = (message: IChatMessage) => {
        Modal.confirm({
            closable: true,
            title: 'Вы действительно хотите удалить сообщение?',
            onOk: () => onDeleteConfirmed(message),
            okType: 'danger',
        });
    };

    const onMessageSend = async (message: string) => {
        setSendLoading(true);

        const sendingMessageId = uuid();

        const sendingMessage: IChatMessage = {
            avatar: '',
            direction: 'outgoing',
            id: sendingMessageId,
            message,
            pending: true,
            read: true,
            received: false,
            sender: '',
            sentTime: moment().format('DD.MM.YYYY HH:mm'),
            created_at: new Date(),
        };

        setList(produce(list, (draft) => {
            draft.map((m) => {
                m.read = true;
                return m;
            });
            draft.push(sendingMessage);
        }));

        const response = await api.fetchData(sendMessageAction, 'post', true, {
            id: sendingMessageId,
            message,
        });

        if (!response.success) {
            setErrorMessage(sendingMessageId);
            notification.error({
                message: 'Сообщение не отправлено',
            });
        }

        setSendLoading(false);
    };

    const onMessageDeleted = () => {
        if (typeof deletedMessage === 'string') {
            setList(produce(list, (draft) => {
                const index = draft.findIndex((el) => el.id === deletedMessage);
                if (index > -1) {
                    draft.splice(index, 1);
                }
                return draft;
            }));
        }
    };

    const onMessagesDeleted = () => {
        if (deletedMessages.length > 0) {
            const nextState = produce(list, (draft) => draft.filter((el) => !deletedMessages.includes(el.id)));
            setList(nextState);
        }
    };

    const onNewMessageReceived = async () => {
        if (typeof newMessage === 'string') {
            const index = list.findIndex((el) => el.id === newMessage);
            if (index < 0) {
                const result = await api.fetchData<IChatMessage>(messageDataLink.replace('{id}', newMessage), 'post', true);
                if (result.success && result.data) {
                    setList(produce(list, (draft) => {
                        if (result.data) {
                            draft.push(result.data);
                        }
                    }));
                }
            } else {
                setList(produce(list, (draft) => {
                    draft[index].pending = false;
                }));
            }
        }
    };

    const nextPage = () => {
        setShowNextPage(true);
    };

    useEffect(() => {
        getList();
    }, [page]);

    useEffect(() => {
        openSocket();
        return () => {
            socket.current?.close();
        };
    }, []);

    useEffect(() => {
        onNewMessageReceived();
    }, [newMessage]);

    useEffect(() => {
        onMessageDeleted();
    }, [deletedMessage]);

    useEffect(() => {
        onMessagesDeleted();
    }, [deletedMessages]);

    useEffect(() => {
        if (typeof receivedMessage === 'string') {
            setList(produce(list, (draft) => {
                const index = draft.findIndex((message) => message.id === receivedMessage);
                if (index > -1) {
                    for (let i = index; i > -1; i -= 1) {
                        draft[i].received = true;
                    }
                }
                return draft;
            }));
        }
    }, [receivedMessage]);

    useEffect(() => {
        if (showNextPage && typeof lastPage === 'number' && lastPage > page) {
            setPage((prev) => prev + 1);
        }
    }, [showNextPage]);

    useEffect(() => {
        if (typeof errorMessage === 'string') {
            setList(produce(list, (draft) => {
                const index = draft.findIndex((el) => el.id === errorMessage);
                if (index > -1) {
                    draft.splice(index, 1);
                }
                return draft;
            }));
        }
    }, [errorMessage]);

    return {
        deletingMessage,
        list,
        loading,
        onDelete,
        onMessageSend,
        sendLoading,
        nextPage,
    };
};
