import { useEffect, useRef, useState } from 'react';
import { IUseLayout, useLayout } from '../useLayout';

export interface IUseModalEdit extends IUseLayout {
    hide: () => void;
    show: (id?: number|string, copy?: boolean) => void;
    visible: boolean;
    objectId?: number|string;
    shouldUpdateTableData: boolean;
    updateTableData: () => void;
    copy: boolean;
}

export const useModalEdit = (): IUseModalEdit => {
    const [visible, setVisible] = useState(false);
    const [objectId, setObjectId] = useState<number|string>();
    const [shouldUpdateTableData, setShouldUpdateTableData] = useState(false);

    const shouldCopy = useRef(false);
    const layoutHook = useLayout();

    const hide = () => setVisible(false);

    const show = (id?: number|string, copy = false) => {
        shouldCopy.current = copy;
        setVisible(true);
        if (typeof id === 'string' || typeof id === 'number') {
            setObjectId(id);
        }
    };

    const updateTableData = () => setShouldUpdateTableData(true);

    useEffect(() => {
        if (!visible) {
            layoutHook.clearLayout();
            setObjectId(undefined);
        }
    }, [visible]);

    useEffect(() => {
        if (shouldUpdateTableData) {
            setShouldUpdateTableData(false);
        }
    }, [shouldUpdateTableData]);

    return {
        ...layoutHook,
        hide,
        visible,
        shouldUpdateTableData,
        show,
        objectId,
        updateTableData,
        copy: shouldCopy.current,
    };
};
