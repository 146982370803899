import { ICellColors, TCellColors, TRowColors } from '../types/layouts/table';

export const replaceCellNullValue = (value: string|null) => {
    if (value === null) {
        return '-';
    }
    return value;
};

export const getRowColor = (rowColors: TRowColors, record: Record<string, any>): ICellColors|undefined => {
    let colors: ICellColors|undefined;

    rowColors.forEach((el) => {
        const filtered = el.values.filter((f) => {
            const columnValue = record[f.column];

            switch (f.operator) {
                case '<': {
                    return f.values.filter((val) => val > columnValue).length === f.values.length;
                }
                case '>': {
                    return f.values.filter((val) => val < columnValue).length === f.values.length;
                }
                case '=':
                default: {
                    return f.values.filter((val) => val === columnValue).length === f.values.length;
                }
            }
        });
        if (filtered.length === el.values.length) {
            colors = el.colors;
        }
    });

    return colors;
};

export const getCellColor = (cellColors: TCellColors, column: string, record: Record<string, any>): ICellColors|undefined => {
    const cc = cellColors.filter((el) => el.column === column);
    let colors: ICellColors|undefined;

    cc.forEach((el) => {
        const filtered = el.values.filter((f) => {
            const columnValue = record[f.column];

            switch (f.operator) {
                case '<': {
                    return f.values.filter((val) => val > columnValue).length === f.values.length;
                }
                case '>': {
                    return f.values.filter((val) => val < columnValue).length === f.values.length;
                }
                case '=':
                default: {
                    return f.values.filter((val) => val === columnValue).length === f.values.length;
                }
            }
        });
        if (filtered.length === el.values.length) {
            colors = el.colors;
        }
    });

    return colors;
};
