import {
    Button, Drawer, Dropdown, Grid, Menu,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LogoutOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import { useAuthenticate } from '../../hooks/api/auth/useAuthenticate';
import { useMenu } from '../../hooks/api/useMenu';
import { IMenuItem } from '../../types';
import { useUser } from '../../hooks/useUser';

const { useBreakpoint } = Grid;

const AppMenu = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const { logout } = useAuthenticate();
    const menu = useMenu();
    const { objectName } = useParams();
    const navigate = useNavigate();
    const user = useUser(false);
    const { xl } = useBreakpoint();

    const navigateToFirstMenuPage = () => {
        let found = false;
        let i = 0;
        while (!found) {
            const { path } = menu.menuData[i];
            if (typeof path === 'string') {
                found = true;
                navigate(path);
            }
            i += 1;
        }
    };

    useEffect(() => {
        menu.getMenuData();
    }, []);

    useEffect(() => {
        if (typeof objectName === 'undefined' && menu.menuData.length > 0) {
            navigateToFirstMenuPage();
        }
    }, [objectName, menu.menuData]);

    useEffect(() => {
        setSelectedKeys(typeof objectName === 'string' ? [`.$${objectName}`] : []);
    }, [objectName]);

    const renderMenu = (data: IMenuItem[]) => React.Children.toArray(data.map((item) => {
        if (Array.isArray(item.items)) {
            return (
                <Menu.SubMenu key={item.key} title={item.title}>
                    {renderMenu(item.items)}
                </Menu.SubMenu>
            );
        }
        if (typeof item.path === 'string') {
            return (
                <Menu.Item key={item.key}>
                    <Link to={item.path}>
                        {item.title}
                    </Link>
                </Menu.Item>
            );
        }
        return null;
    }));

    if (xl) {
        return (
            <>
                <img alt="Logo" src="/assets/logo.png" />
                <Menu mode="horizontal" theme="dark" selectedKeys={selectedKeys}>
                    {renderMenu(menu.menuData)}
                </Menu>
                {(!menu.loading || menu.menuData.length > 0) && (
                    <Dropdown.Button
                        overlay={(
                            <Menu>
                                <Menu.Item onClick={logout} icon={<LogoutOutlined />}>
                                    Выйти
                                </Menu.Item>
                            </Menu>
                        )}
                        icon={<UserOutlined />}
                    >
                        {typeof user.info?.fio === 'string' && <span>{user.info?.fio}</span>}
                    </Dropdown.Button>
                )}
            </>
        );
    }

    return (
        <>
            <div className="d-flex flex-1 align-items-center justify-content-between">
                <img alt="Logo" src="/assets/logo.png" style={{ height: 50 }} />
                <Button icon={<MenuOutlined />} onClick={() => setShowDrawer(true)} type="primary" />
            </div>
            <Drawer placement="left" visible={showDrawer} onClose={() => setShowDrawer(false)}>
                <Menu mode="inline" selectedKeys={selectedKeys}>
                    <Menu.Item>
                        <h3 className="mb-0">Меню</h3>
                    </Menu.Item>
                    <Menu.Divider />
                    {renderMenu(menu.menuData)}
                    <Menu.Divider />
                    <Menu.SubMenu title={user.info?.fio || 'Пользователь'} icon={<UserOutlined />}>
                        <Menu.Item onClick={logout}>
                            Выйти
                        </Menu.Item>
                    </Menu.SubMenu>
                </Menu>
            </Drawer>
        </>
    );
};

export default AppMenu;
