import moment from 'moment';
import { TFormItems } from '../types/layouts/form';
import { TFormItems as TFormItemsNames } from '../components/form-items';

const parseFormValue = (value: any, type: TFormItemsNames, inForm: boolean) => {
    try {
        switch (type) {
            case 'date': {
                return inForm ? moment(value, 'YYYY-MM-DD') : moment(value).format('YYYY-MM-DD');
            }
        }
        return value;
    } catch (err) {
        console.error('Ошибка парсинга эл-а формы', err);
        return value;
    }
};

/**
 * Парсинг эл-в формы, исходя из их типов
 * @param values - данные формы
 * @param fields - эл-ы формы
 * @param inForm - идентификатор для чего парсинг, false - отправить на сервере, true - обработать для формы
 */
export const parseFormValues = (values: Record<string, any>, fields: TFormItems, inForm = false): Record<string, any> => {
    const body: Record<string, any> = {
        ...values,
    };
    const keys = Object.keys(values);
    keys.forEach((key) => {
        const fieldParams = fields.find((field) => field.name === key);
        if (fieldParams) {
            body[key] = parseFormValue(values[key], fieldParams.type, inForm);
        }
    });
    return body;
};
