/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Input from 'react-input-mask';
import { IMaskedInputLayout } from '../../types/layouts/masked-input';
import { IFormItemProps } from '../../factories/form';

const MaskedInput = ({
    defaultValue, formDataLoaded, formRef, formKey, mask,
}: IMaskedInputLayout['config'] & IFormItemProps) => {
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        if (formDataLoaded) {
            const values = formRef.getFieldsValue();
            setValue(values[formKey] || defaultValue || '');
        }
    }, [formDataLoaded]);

    useEffect(() => {
        if (formRef && typeof value === 'string') {
            formRef.setFieldsValue({
                [formKey]: value,
            });
        }
    }, [value]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

    return (
        <Input className="ant-input" mask={mask || ''} value={value} onChange={onChange} />
    );
};

export default MaskedInput;
