import { useContext, useEffect } from 'react';
import { useApiFetch } from './api/useApiFetch';
import { IUserInfo } from '../types/api/user';
import { UserContext } from '../context/user';

export const useUser = (canFetch = true) => {
    const api = useApiFetch();
    const context = useContext(UserContext);

    const getData = async () => {
        context.loading.setter(true);

        const response = await api.fetchData<IUserInfo>('/user', 'get', true);

        if (response.success && response.data instanceof Object) {
            context.info.setter(response.data);
        }

        context.loading.setter(false);
    };

    useEffect(() => {
        if (canFetch) {
            getData();
        }
    }, []);

    return {
        info: context.info.value,
        loading: context.loading.value,
    };
};
